.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 3rem;
  padding: 0.5rem 0;
  text-transform: uppercase;
}

.logoContainer {
  flex-grow: 1;
  align-self: center;
  padding: 1.5rem 5%;
}

.logo {
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  text-decoration: none;
}


@media screen and (max-width: 900px) {
  .header {
    flex-direction: column;
  }
}