.image {
  /* https://web.dev/articles/aspect-ratio#example_preventing_layout_shift */
  width: 100%;
  height: auto;
  max-width: 600px;
  aspect-ratio: 6 / 4;
  background-color: rgb(245, 245, 245);
}

@media screen and (min-width: 1000px) {
  /* Pop out effect on hover */
  .image {
    left: 0;
    bottom: 0;
  }

  .image:hover {
    position: relative;
    left: 6px;
    bottom: 6px;
    box-shadow: -6px 6px 4px rgb(200, 200, 200);
    transition: all 0.25s;
  }
}
