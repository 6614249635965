/* ===== Image ===== */

.image {
  /* https://web.dev/articles/aspect-ratio#example_preventing_layout_shift */
  width: 100%;
  height: auto;
  aspect-ratio: 6 / 4;
  background-color: rgb(245, 245, 245);
}


/* ===== Key Details ===== */

.keyDetails {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2.25em;
  color: rgb(115, 115, 115);
  font-size: 1.05em;
  line-height: 1.5;
}

.detailItem {
  margin: 0.5em 2.5em;
}

.locationIcon {
  position: relative;
  top: 5px;
  right: 3px;
}

.calendarIcon {
  position: relative;
  top: 6px;
  right: 5px;
}

@media screen and (max-width: 800px) {
  .keyDetails {
    flex-direction: column;
    margin-bottom: 1.5em;
  }
  
  .detailItem {
    margin: 0.3em 0;
  }
}


/* ===== Description ===== */

.description {
  margin-bottom: 4em;
  line-height: 1.6;
}

@media screen and (min-width: 1400px) {
  .description {
    padding: 0 10%;
  }
}


/* ===== Related Collections ===== */

.relatedCollections {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  list-style-type: none;
}

.collectionLink {
  margin: 0.8em 1em;
}

@media screen and (max-width: 450px) {
  .relatedCollections {
    flex-direction: column;
  }
  
  .collectionLink {
    width: 80%;
    margin: 0.8em 0;
  }
}
