/* ===== Page Layout & Spacing ===== */

.pagePadding {
  padding: 0 12.5% 4rem 12.5%;
}

@media screen and (max-width: 1400px) {
  .pagePadding {
    padding: 0 5% 4rem 5%;
  }
}

.pagePaddingNarrow {
  padding: 0 15% 4rem 15%;
}

@media screen and (max-width: 900px) {
  .pagePadding,
  .pagePaddingNarrow {
    padding: 0 2.5% 4rem 2.5%;
  }
}

.mt3rem {
  margin-top: 3rem;
}

.mb2rem {
  margin-bottom: 2rem;
}

.textCenter {
  text-align: center;
}


/* ===== Typography ===== */

.h1 {
  color: rgb(0, 0, 0);
  font-size: 3rem;
  font-weight: 200;
}

.h2 {
  color: rgb(0, 0, 0);
  font-size: 2rem;
  font-weight: 300;
}

@media screen and (max-width: 900px) {
  .h1 {
    font-size: 1.8rem;
    font-weight: 300;
  }

  .h2 {
    font-size: 1.4rem;
    font-weight: 400;
  }
}


/* ===== Buttons & Links ===== */

.btn,
.btn:link,
.btn:visited {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1em 1.4em;
  background-color: rgb(240, 240, 240);
  border: 1px solid transparent;
  border-radius: 8px;
  color: rgb(25, 25, 25);
  font-size: 0.95rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  transition: border 0.4s;
}

.btn:focus,
.btn:hover {
  cursor: pointer;
  border: 1px solid rgb(120, 120, 120);
  transition: border 0.5s;
}

.btn:active {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
}
