.photoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  column-gap: 5%;
  row-gap: 4rem;
  margin-top: 5rem;
}

@media screen and (max-width: 900px) {
  .photoGrid {
    grid-template-columns: 1fr;
    margin-top: 3rem;
    row-gap: 2rem;
  }
}
